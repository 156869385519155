import * as captcha from './captcha-google.js';

/**
 * @file This file collects all js code related to the login popup and the login page.
 *       It also contains the code for the password reset and the customer registration.
 * TODO Move all login code to this file.
 * TODO seperate login from reset password logic and registration logic or rename file
 * TODO extract functions which are unrelated to login
 */

window.addEventListener('load', function() {
  document.querySelectorAll('.js_login_form').forEach(addLoginFormListeners);

  const loginPopup = document.getElementById('login-popup');
  if (loginPopup !== null) {
    addLoginPopupListeners(loginPopup);
  }

  addFormSubmitEvents();
  window.recaptchaIds = [];

  addThirdPartyLoginListener();
});

const DEFAULT_ERROR = 'Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut.';

/**
 * Adds event listeners to the given login form.
 *
 * @param {HTMLElement} loginForm
 */
function addLoginFormListeners(loginForm) {
  const passwordInput = loginForm.querySelector('input[type="password"]');
  const capsError = loginForm.querySelector('.pw_caps_error');
  passwordInput.addEventListener('keyup', (event) => {checkCapsLock(event, capsError);});
  passwordInput.addEventListener('keydown', (event) => {checkCapsLock(event, capsError);});
  passwordInput.addEventListener('blur', () => {capsError.style.display = 'none';});

  const loginEye = loginForm.querySelector('.login_eye');
  loginEye.addEventListener('click', function() {
    togglePasswordVisibility(passwordInput, loginEye);
  });
}

/**
 * Adds event listeners to the login popup.
 *
 * @param {HTMLElement} loginPopup
 */
function addLoginPopupListeners(loginPopup) {
  loginPopup.querySelectorAll('.js_change_view_button').forEach(function(el) {
    el.addEventListener('click', function(event) {
      event.preventDefault();
      changeLoginView(el.dataset.target);
    });
  });

  loginPopup.querySelectorAll('.close_wrapper, .js_close_login_popup').forEach(function(el) {
    el.addEventListener('click', () => {
      hidePopup('login-popup');
      changeLoginView('js_login_main');
    });
  });

  // Close the popup when the escape key is pressed
  document.addEventListener('keydown', function(event) {
    if (event.key === 'Escape') {
      hidePopup('login-popup');
      changeLoginView('js_login_main');
    }
  });
}

/**
 * Adds submit events to all login forms.
 */
function addFormSubmitEvents() {
  addFormSubmitEvent('.js_reset_password_form', resetPasswordRequest, 'js_login_reset_password');
  addFormSubmitEvent('.js_find_shop_customer_form', resetPasswordRequest, 'js_login_register_shop');
  addFormSubmitEvent('.js_find_new_customer_form', findNewCustomer);
  addFormSubmitEvent('.js_register_new_customer_form', registerNewCustomer);
  addFormSubmitEvent('.js_login_form', login);
  addFormSubmitEvent('.js_find_order_form', findKundeByOrder);
}

/**
 * Checks the state of the caps lock key and displays an error message if it is active.
 *
 * @param {Event} e
 * @param {HTMLElement} capsError
 */
function checkCapsLock(e, capsError) {
  if (!(e instanceof KeyboardEvent)) {
    return;
  }

  if (e.getModifierState('CapsLock')) {
    capsError.style.display = 'block';
  } else {
    capsError.style.display = 'none';
  }
}

/**
 * Toggle the visibility of the password in the given input.
 *
 * @param {HTMLInputElement} passwordInput
 * @param {HTMLElement} loginEye
 */
function togglePasswordVisibility(passwordInput, loginEye) {
  if (passwordInput.type === 'password') {
    passwordInput.type = 'text';
    loginEye.querySelector('.eye_open').style.display = 'none';
    loginEye.querySelector('.eye_closed').style.display = 'block';
  } else {
    passwordInput.type = 'password';
    loginEye.querySelector('.eye_closed').style.display = 'none';
    loginEye.querySelector('.eye_open').style.display = 'block';
  }
}

/**
 * @param {string} formSelector
 * @param callback
 * @param {string|null} backButtonTarget
 */
function addFormSubmitEvent(formSelector, callback, backButtonTarget = null) {
  const form = document.querySelector(formSelector);
  if (form !== null) {
    form.addEventListener('submit', function(event) {
      event.preventDefault();
      if (backButtonTarget === null) {
        callback(form);
      } else {
        callback(form, backButtonTarget);
      }
    });
  }
}

/**
 * Changes the content of the login popup to the given view.
 *
 * @param {string} view
 */
function changeLoginView(view) {
  // Reset all error messages
  resetMessages();

  // Reset the captchas
  resetAllV2Captchas();

  document.querySelectorAll('.login_view').forEach(function(el) {
    el.style.display = el.classList.contains(view) ? 'block' : 'none';
  });
}

/**
 * Reset all recaptcha v2 challenges.
 *
 * This is necessary because we don't always reload the page after an
 * ajax call, so the user might need to solve another captcha.
 */
function resetAllV2Captchas() {
  for (const recaptchaId of window.recaptchaIds) {
    captcha.reset(recaptchaId);
  }
}

/**
 * Fetches the given url after processing the captcha data.
 *
 * @param {HTMLFormElement} form
 * @param {string} url
 * @param onSuccess
 * @param onFail
 * @param {string} recaptchaAction
 */
function fetchWithCaptcha(form, url, onSuccess, onFail, recaptchaAction) {
  resetMessages();
  let formData = new FormData(form);

  // Check if a recaptcha v2 has been solved
  if (formData.has('g-recaptcha-response')) {
    fetchWithCaptchaV2(form, url, formData, onSuccess, onFail);
  } else {
    // If no recaptcha v2 has been solved, use the invisible recaptcha v3
    fetchWithCaptchaV3(form, url, formData, onSuccess, onFail, recaptchaAction);
  }
}

/**
 * Fetches the given url after processing the captcha data.
 * This function is used for recaptcha v2.
 *
 * @param {HTMLFormElement} form
 * @param {string} url
 * @param {FormData} formData
 * @param onSuccess
 * @param onFail
 */
function fetchWithCaptchaV2(form, url, formData, onSuccess, onFail) {
  fetch(url, {method: 'post', body: formData}).then((rawResponse) => {
    if (!rawResponse.ok) {
      setMessage(form, DEFAULT_ERROR);
      return;
    }

    rawResponse.json().then((response) => {
      resetAllV2Captchas();
      if (response.success) {
        onSuccess(response);
      } else {
        onFail(response);
      }
    });
  });
}

/**
 * @param {HTMLFormElement} form
 * @param {Request | string | URL} url
 * @param {FormData} formData
 * @param onSuccess
 * @param onFail
 * @param {string} recaptchaAction
 */
function fetchWithCaptchaV3(form, url, formData, onSuccess, onFail, recaptchaAction) {
  captcha.execute(recaptchaAction, function(token) {
    formData.append('recaptchaResponse', token);

    fetch(url, {method: 'post', body: formData}).then((rawResponse) => {
      if (!rawResponse.ok) {
        setMessage(form, DEFAULT_ERROR);
        return;
      }

      rawResponse.json().then((response) => {
        if (response.success) {
          onSuccess(response);
        } else if (response.error === 'captcha_failed') {
          setMessage(form, 'Bitte bestätige, dass du kein Roboter bist.');
          renderRecaptchaChallenge(form);
        } else {
          onFail(response);
        }
      });
    });
  });
}

function resetPasswordRequest(resetPasswordForm, backButtonTarget) {
  fetchWithCaptcha(
      resetPasswordForm,
      '/loginActionsEndpoint.php?action=requestPasswordReset',
      (response) => { showMailSentView(response.data.mail); },
      (response) => {
        if (response.error === 'unknown_email') {
          showCustomerServiceView(
              'Sorry, leider konnten wir dein Kundenkonto nicht finden.',
              'Wir haben unter deiner angegebenen E-Mail-Adresse kein registriertes Kundenkonto gefunden.',
              backButtonTarget,
          );
        } else {
          setMessage(resetPasswordForm, response.error);
        }
      },
      'resetPassword'
  );
}

function findNewCustomer(findNewCustomerForm) {
  const mail = new FormData(findNewCustomerForm).get('email');
  fetchWithCaptcha(
      findNewCustomerForm,
      '/loginActionsEndpoint.php?action=requestPasswordReset',
      (response) => { showMailSentView(response.data.mail); },
      (response) => {
        if (response.error === 'unknown_email') {
          changeLoginView('js_login_register_data');
          document.querySelector('.js_login_data_email').value = mail;
        } else {
          setMessage(findNewCustomerForm, response.error);
        }
      },
      'resetPassword'
  );
}

function registerNewCustomer(registerNewCustomerForm) {
  fetchWithCaptcha(
      registerNewCustomerForm,
      '/loginActionsEndpoint.php?action=registerNew',
      () => {
        changeLoginView('js_login_main');
        setMessage(
            document.querySelector('.js_login_form'),
            'Vielen Dank! Wir haben eine E-Mail an die angegebene Adresse gesendet.'
        );
      },
      (response) => {
        if (response.error === 'deleted_mail') {
          showCustomerServiceView(
              'Es ist ein Problem bei deiner Registrierung aufgetreten.',
              'Bitte kontaktiere den Kundenservice um deinen Account zu aktivieren.',
              'js_login_register_marketplace',
          );
        } else {
          setMessage(registerNewCustomerForm, response.error);
        }
      },
      'register'
  );
}

function login(loginForm) {
  fetchWithCaptcha(
      loginForm,
      '/loginActionsEndpoint.php?action=login',
      (response) => { window.location = response.data.target; },
      (response) => { setMessage(loginForm, response.error); },
      'login'
  );
}

function findKundeByOrder(findKundeByOrderForm) {
  fetchWithCaptcha(
      findKundeByOrderForm,
      '/loginActionsEndpoint.php?action=findKundeByOrder',
      (response) => { showMailSentView(response.data.mail); },
      (response) => {
        if (response.error === 'no_email') {
          showCustomerServiceView(
              'Wir haben dein Konto gefunden, aber keine E-Mail-Adresse hinterlegt.',
              'Bitte kontaktiere den Kundenservice um deine E-Mail-Adresse zu hinterlegen.',
              'js_login_register_marketplace',
          );
        } else if (response.error === 'no_order_found') {
          showCustomerServiceView(
              'Sorry, leider konnten wir deine Bestellung nicht finden.',
              'Unter der von dir angegebenen IMEI oder Rechnungsnummer konnten wir leider kein Kundenkonto finden.',
              'js_login_register_marketplace',
          );
        } else {
          setMessage(findKundeByOrderForm, response.error);
        }
      },
      'findKundeByOrder'
  );
}

/**
 * Renders the recaptcha v2 challenge in the given form
 *
 * @param {HTMLFormElement} form
 */
function renderRecaptchaChallenge(form) {
  const challengeElement = form.querySelector('.js_recaptcha_v2_challenge');

  // If there is no element to render the challenge in just return
  if (challengeElement === null) {
    return;
  }

  captcha.renderMultipleCaptchasOnSamePage(
      form.querySelector('.js_recaptcha_v2_challenge'));
}

/**
 * Shows the customer service view with the given content
 *
 * @param {string} heading
 * @param {string} description
 * @param {string} backButtonTarget
 */
function showCustomerServiceView(heading, description, backButtonTarget) {
  const backButton = document.querySelector('.js_login_customer_service .js_change_view_button');
  if (backButton) {
    backButton.dataset.target = backButtonTarget;
  }
  document.querySelector('.js_login_customer_service .h3_alternative').innerHTML = heading;
  document.querySelector('.js_customer_service_description').innerHTML = description;
  changeLoginView('js_login_customer_service');
}

function showMailSentView(mail) {
  changeLoginView('js_login_mail_sent');
  document.querySelector('.js_login_mail_sent .masked_email').innerHTML = mail;
}

/**
 * Shows an error message in the given form
 *
 * @param {HTMLFormElement} form
 * @param {string} message
 */
function setMessage(form, message) {
  const messageElement = form.querySelector('.login_error');
  messageElement.innerHTML = message;
  messageElement.style.display = 'block';
}

/**
 * Hides all login error messages
 */
function resetMessages() {
  document.querySelectorAll('.login_error').forEach((el) => {
    el.style.display = 'none';
  });
}

// TODO Tranform AJAX to vanilla JS and transform ajaxCall to fetch
// TODO Extract common parts to one centralized login function
export function doHiddenLogin() {
  var data = {};
  $.each($('#hidden-loginform').serializeArray(), function(_, kv) {
    if (kv.name == 'login_mail' || kv.name == 'login_pw') {
      if (kv.name == 'login_mail') {
        data['email'] = kv.value;
      }
      if (kv.name == 'login_pw') {
        data['password'] = kv.value;
      }
    } else {
      data[kv.name] = kv.value;
    }
  });

  // Get recaptcha token and send ajax call
  captcha.execute('login', function(token) {
    data.recaptchaResponse = token;
    doAjaxCall('/loginActionsEndpoint.php?action=login', data, function(res) {
      if (res.success) {
        window.location = window.location.href.split('?')[0];
      } else {
        $('#login-error').html(res.error).removeClass('hidden');
      }
    });
  });
}

// TODO Tranform AJAX to vanilla JS and transform ajaxCall to fetch
// TODO Extract common parts to one centralized login function
export function loginPersonalData() {
  let data = {
    email: document.getElementById('loginMail-PersonalData').value,
    password: document.getElementById('loginPw-PersonalData').value,
  };

  // Get recaptcha token and send ajax call
  captcha.execute('login', function(token) {
    data.recaptchaResponse = token;
    doAjaxCall('/loginActionsEndpoint.php?action=login', data, function(res) {
      if (res.success) {
        window.location.href = '//' + location.host + location.pathname +
            '?login=success';
      } else {
        let loginError = $('#loginError-Personaldata');
        loginError.html(res.error);
        loginError.show();
      }
    });
  });
}

export function submitPwForgotRequest(event) {
  event.preventDefault();
  const formData = new FormData(event.target);
  const action = 'requestPasswordResetByCustomerId';

  // Check if a recaptcha v2 has been solved
  if (formData.has('g-recaptcha-response')) {
    submitPwForgotRequestv2(formData, action);
  } else {
    // If no recaptcha v2 has been solved, use the invisible recaptcha v3
    submitPwForgotRequestv3(formData, action);
  }
}

function submitPwForgotRequestv3(formData, action) {
  captcha.execute(action, function(token) {
    formData.append('recaptchaResponse', token);

    fetch('/loginActionsEndpoint.php?action=' + action, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      res.json().then((data) => {
        const errorElement = document.querySelector('.login_postcode_error');
        if (data.success) {
          errorElement.innerHTML = 'Den Login-Link haben wir an folgende E-Mail-Adresse versendet: ' +
              data.data.email;
        } else if (data.error === 'captcha_failed') {
          errorElement.innerHTML = 'Bitte bestätige, dass du kein Roboter bist.';
          const challengeElement = document.querySelector(
              '.js_ruecksende_recaptcha_v2_challenge');

          captcha.renderV2WhenReady(challengeElement);
        } else {
          errorElement.innerHTML = data.error;
        }
      });
    });
  });
}

function submitPwForgotRequestv2(formData, action) {
  fetch('/loginActionsEndpoint.php?action=' + action, {
    method: 'POST',
    body: formData,
  }).then((res) => {
    res.json().then((data) => {
      const errorElement = document.querySelector('.login_postcode_error');
      if (data.success) {
        errorElement.innerHTML = 'Den Login-Link haben wir an folgende E-Mail-Adresse versendet: ' +
            data.email;
      } else {
        errorElement.innerHTML = data.error;
      }
    });
  });
}

function addThirdPartyLoginListener() {
  document.querySelectorAll('.js_third_party_login').forEach(function(el) {
    el.addEventListener('click', function() {
      redirectToThirdPartyProvider(el.dataset.provider);
    });
  });
}

function redirectToThirdPartyProvider(providerName) {
  let formData = new FormData();
  formData.append('provider', providerName);
  fetch('/loginActionsEndpoint.php?action=redirectToThirdPartyProvider', {
    method: 'POST',
    body: formData,
  }).then((res) => {
    res.json().then((data) => {
      if (data.success) {
        window.location = data.data.url;
      } else {
        setMessage(document.querySelector('.js_login_form'), data.error);
      }
    });
  });
}